<template>
  <div
    class="payment-type payment-type-delivery"
    data-test="payment-type-delivery"
  >
    <div
      class="payment-type-title"
      v-html="paymentType.title"
      v-if="paymentType.title"
    ></div>

    <div class="payment-type-description" v-html="paymentType.descr"></div>
    <v-alert
      v-if="error"
      type="error"
      border="left"
      transition="v-fab-transition"
    >
      {{ error }}
    </v-alert>
    <div
      v-if="$te('paymentTypeList.redirectText.' + paymentType.paymentTypeId)"
      v-html="$t('paymentTypeList.redirectText.' + paymentType.paymentTypeId)"
      class="redirect-text"
    ></div>
    <ResponseMessage :response="response" />
    <v-row class="d-flex justify-end">
      <v-col
        cols="12"
        md="6"
        class="d-flex align-center"
        v-if="mode != 'order' && !options.hidePrevButton"
      >
        <v-btn
          v-if="options.back.show"
          :color="options.back.color"
          :outlined="options.back.outline"
          :link="options.back.link"
          depressed
          v-on:click="goBack"
          :disabled="loading"
          :large="options.back.large"
          :class="options.back.class"
        >
          <v-icon v-if="options.back.icon != null">
            {{ options.back.icon }}
          </v-icon>
          {{ options.back.label }}
        </v-btn>
      </v-col>
      <v-col cols="12" md="6" class="d-flex justify-end">
        <v-btn
          v-if="options.confirm.show"
          :color="options.confirm.color"
          :outlined="options.confirm.outline"
          class="mt-3 mt-sm-0 ml-2 gateway-btn"
          :depressed="options.confirm.depressed"
          :large="options.confirm.large"
          :class="options.confirm.class"
          v-on:click="buy"
        >
          {{ options.confirm.label }}
        </v-btn>
      </v-col>
    </v-row>
    <i18n
      v-if="$te(`paymentTypeList.${paymentType.gatewayClient}.caption`)"
      :path="`paymentTypeList.${paymentType.gatewayClient}.caption`"
      tag="div"
      class="caption"
    />
  </div>
</template>
<script>
import ResponseMessage from "@/components/ResponseMessage";
import OrderService from "~/service/orderService";
import gateway from "~/mixins/gateway";

import { mapActions } from "vuex";
var vm;

export default {
  name: "PaymentTypeDelivery",
  mixins: [gateway],
  components: { ResponseMessage },
  data() {
    return { loading: false, error: null, response: {} };
  },
  methods: {
    ...mapActions({
      buyCart: "cart/buyCart"
    }),
    async buy() {
      // UPDATE CART INFO AND PAY
      vm.loading = true;
      try {
        if (vm.mode == "cart") {
          let cart = await vm.buyCart({
            paymentTypeId: this.paymentType.paymentTypeId
          });
          if (cart) {
            let orderId = cart.order ? cart.order.orderId : cart.orderId;
            global.EventBus.$emit("pay-response-ok", cart);
            this.$router.push("/cart/payment-completed/" + orderId);
          } else {
            this.$router.push("/payment-error");
          }
        } else if (vm.mode == "order") {
          let response = await OrderService.modifyOrderPaymentType(
            vm.orderId,
            this.paymentType.paymentTypeId
          );
          if (response) {
            global.EventBus.$emit("pay-response-ok", response);
            this.$router.push("/cart/payment-completed/" + vm.orderId);
          } else {
            this.$router.push("/cart/payment-error");
          }
        }
      } catch (err) {
        vm.response = err;
      } finally {
        vm.loading = false;
      }
    },
    goBack() {
      this.$router.push("/checkout");
    }
  },
  created() {
    vm = this;
  }
};
</script>
